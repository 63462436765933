import { useMutation } from "@tanstack/react-query";

import { auditAmalgamationOrSubdivision } from "@services/administrativeApprovals";

export const useAuditAmalgamationOrSubdivision = (
  options: Record<string, any> = {},
) => {
  return useMutation(auditAmalgamationOrSubdivision, {
    ...options,
  });
};
