import { useMutation } from "@tanstack/react-query";

import api from "@services/api";

const resetAccountingPeriod = async (payload: {
  accountingPeriodCheckId: string;
  nextAccountingPeriodId: string;
}) => {
  const { data } = await api.post(
    "/admin/accountingPeriodChecks/reset",
    payload,
  );

  return [data.accountingPeriodCheck, data.workflowInstance];
};

export const useResetAccountingPeriod = (options: Record<string, any> = {}) => {
  return useMutation(resetAccountingPeriod, options);
};
