import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { useExtractionPointContext } from "@context/ExtractionPointContext";
import { useCreateExtractionPoint } from "@hooks/mutation/useCreateExtractionPoint";
import { useUpdateExtractionPoint } from "@hooks/mutation/useUpdateExtractionPoint";
import { toastError, toastSuccess } from "@utils/toast";
import { isValidationError } from "@utils/formError";
import { convertMLToLiter } from "@utils/convertUnits";
import { DBTables } from "@utils/constants/dbTables";

const ExtractionPointLedger: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    details,
    locationCoordinates,
    navigateForCancel,
    setNetworkErrors,
    setWorkflowCompleted,
    setWorkflowInstance,
    handleChangeDetails,
    updatingPoint,
    info,
  } = useExtractionPointContext();
  const {
    archiveUnselectedEvidences,
    uploadEvidences,
    isSubmitting,
    isArchivingUnselectedEvidence,
    getEvidencesInfo,
  } = useEvidenceContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const {
    mutateAsync: createExtractionPointMutation,
    isLoading: isLoadingCreateExtractionPoint,
  } = useCreateExtractionPoint();
  const {
    mutateAsync: updateExtractionPointMutation,
    isLoading: isLoadingUpdateExtractionPoint,
  } = useUpdateExtractionPoint();

  const handleOnConfirm = async () => {
    const { lat, lng } = locationCoordinates;
    const location = lat !== "" ? `${lat},${lng}` : "";
    const data = {
      name: details.name,
      description: details.description,
      source: details.source,
      level0ResourceId: details.level0wrs?.id,
      address: {
        street: "",
        town: "",
        region: "",
        postcode: "",
        country: "",
        location,
      },
      maxFlow: details.maxFlow ? convertMLToLiter(+details.maxFlow) : 0,
      volLimit: details.volLimit ? convertMLToLiter(+details.volLimit) : 0,
      isActive: false,
      subscriberId: details.subscriber?.id,
      meterId: details.meter.id,
      group: details.group || null,
      sequence: +details.sequence || null,
      type: details.type,
    };
    try {
      let extractionPointId = "";
      let instance: any;

      if (updatingPoint.id) {
        const [res, workflowInstance] = await updateExtractionPointMutation({
          extractionPointId: details.id,
          name: data.name,
          description: data.description,
          maxFlow: data.maxFlow,
          volLimit: data.volLimit,
          source: data.source,
          meterId: data.meterId,
          address: {
            location: data.address.location,
          },
          group: data.group,
          sequence: data.sequence,
        });

        extractionPointId = res.id;
        instance = workflowInstance;
      } else {
        const [res, workflowInstance] =
          await createExtractionPointMutation(data);
        handleChangeDetails("id", res.id);
        extractionPointId = res.id;
        instance = workflowInstance;
      }
      setWorkflowInstance(instance);

      await archiveUnselectedEvidences({
        referenceId: extractionPointId,
        referenceTable: "extraction_points",
        evidenceIds: details.activatedEvidenceIds,
      });

      await uploadEvidences({
        description: t("extraction_point.create.step_5.description", {
          extractionPointName: details.name,
        }),
        references: [
          {
            referenceId: extractionPointId,
            referenceTable: DBTables.ExtractionPoints,
          },
          {
            referenceId: details.subscriber?.id,
            referenceTable: DBTables.Subscribers,
          },
          {
            referenceId: details.level1wrs?.id,
            referenceTable: DBTables.WRSHierarchies,
          },
          {
            referenceId: instance?.id,
            referenceTable: DBTables.WorkflowInstances,
          },
        ],
      });

      setWorkflowCompleted(true);
      if (updatingPoint.id) {
        toastSuccess(
          t("extraction_point.update.toast.success", { name: details.name }),
        );
      } else {
        toastSuccess(
          t("extraction_point.create.message.success", { name: details.name }),
        );
      }

      setNetworkErrors([]);
    } catch (error: any) {
      const toastErrorMessage = t("extraction_point.create.message.error", {
        error: error?.response?.data?.message || error?.message,
      });
      let errorMessages = [];
      if (isValidationError(error)) {
        const { errors = [] } = error?.response?.data;
        errorMessages = errors.map((i: any) => i.message);
      } else {
        errorMessages = [error?.message];
      }
      toastError(
        <>
          <p>{toastErrorMessage}</p>
          {errorMessages.length ? (
            <ul className="list-disc pl-4">
              {errorMessages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          ) : null}
        </>,
      );
      setNetworkErrors(errorMessages);
    }
    setShowConfirmModal(false);
  };

  return (
    <>
      <div className="flex flex-col gap-6 p-6 grow">
        <Heading light>
          {t("extraction_point.create.step_6.definition_complete")}
        </Heading>
        <ConfirmationDetail
          data={[
            info.subscriber,
            info.extractionPoint,
            info.location,
            info.meter,
            getEvidencesInfo(),
          ]}
          onEdit={index =>
            stepHelpers.setStep(updatingPoint ? index - 1 : index)
          }
        />
      </div>
      <footer className="flex gap-4 p-6  border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={() => setShowConfirmModal(true)}
        >
          {t("common.ledger")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => navigateForCancel()}
        >
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleOnConfirm}
        confirmText={t("common.ledger") as string}
        isSubmitting={
          isSubmitting ||
          isArchivingUnselectedEvidence ||
          isLoadingCreateExtractionPoint ||
          isLoadingUpdateExtractionPoint
        }
      >
        {t("extraction_point.create.message.confirmation")}
      </ConfirmModal>
    </>
  );
};

export default ExtractionPointLedger;
