import React from "react";
import Select from "react-select";
import { noop } from "lodash";
import { t as translate } from "i18next";

import ErrorMessage from "@components/form/ErrorMessage";

type SelectProps = React.ComponentProps<Select>;

type SelectExtractionPointTypeProps = Exclude<
  SelectProps,
  "value" | "onChange"
> & {
  value?: number;
  onChange?: (e: any) => void;
  errorMessage?: string;
};

export const getTypeStr = (type: number) => {
  const mapper: Record<number, string> = {
    1: translate("extraction_point.regular"),
    2: translate("extraction_point.quasi"),
  };
  return mapper[type] ?? translate("common.invalid");
};

const SelectExtractionPointType: React.FunctionComponent<
  SelectExtractionPointTypeProps
> = ({ value, onChange = noop, errorMessage, ...restProps }) => {
  const options = [1, 2].map(value => ({
    label: getTypeStr(value),
    value,
  }));

  return (
    <>
      <Select
        options={options}
        value={
          options.find((i: any) => {
            return i.value === value || i.label === value;
          }) || null
        }
        onChange={onChange}
        openMenuOnFocus
        {...restProps}
      />

      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </>
  );
};

export default SelectExtractionPointType;
