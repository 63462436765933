import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import api from "@services/api";

export const createAccountingPeriodCheck = async (payload: {
  accountingPeriodId: string;
}) => {
  const { data } = await api.post(`/admin/accountingPeriodChecks`, payload);

  return [data.accountingPeriodCheck, data.workflowInstance];
};

export const useCreateAccountingPeriodCheck = (
  options: Record<string, any> = {},
) => {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();

  return useMutation(createAccountingPeriodCheck, {
    ...restOptions,
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({
        queryKey: ["accountingPeriodChecks"],
      });

      if (isFunction(onSuccess)) {
        onSuccess(res);
      }
    },
  });
};
