import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import api from "@services/api";

export const updateAccountingPeriodCheck = async (
  params: Record<string, any> = {},
) => {
  const { id, ...payload } = params;
  const { data } = await api.put(
    `/admin/accountingPeriodChecks/${id}`,
    payload,
  );

  return [data.accountingPeriodCheck, data.workflowInstance];
};

export const useUpdateAccountingPeriodCheck = (
  options: Record<string, any> = {},
) => {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();

  return useMutation(updateAccountingPeriodCheck, {
    ...restOptions,
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({
        queryKey: ["accountingPeriodChecks"],
      });

      if (isFunction(onSuccess)) {
        onSuccess(res);
      }
    },
  });
};
