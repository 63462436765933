import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import ExtractionPointDetail from "./ExtractionPointDetail";
import ExtractionPointLocation from "./ExtractionPointLocation";
import ExtractionPointLinkToMeter from "./ExtractionPointLinkToMeter";
import ExtractionPointLedger from "./ExtractionPointLedger";
import ExtractionPointWorkflowCompleted from "./ExtractionPointWorkflowCompleted";
import InfoPanel from "@components/form/InfoPanel";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import WorkflowPanels from "@components/form/WorkflowPanels";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import LocationInformation from "@components/shared/LocationInformation";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import {
  ExtractionPointProvider,
  useExtractionPointContext,
} from "@context/ExtractionPointContext";
import { toastSuccess } from "@utils/toast";

const UpdateExtractionPointWorkflow = () => {
  return (
    <EvidenceProvider>
      <ExtractionPointProvider>
        <Consumer />
      </ExtractionPointProvider>
    </EvidenceProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const {
    currentStep,
    stepHelpers,
    details,
    setLocationCoordinates,
    handleChangeDetails,
    navigateForCancel,
    workflowCompleted,
    networkErrors,
    info,
    updatingPoint,
  } = useExtractionPointContext();
  const navigate = useNavigate();
  const { getEvidencesInfo } = useEvidenceContext();

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={true}
          forceSuccess={true}
          info={<StepInfo data={info.subscriber.body} />}
        >
          {t("extraction_right.create.step_1.select_subscriber")}
        </StepButton>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep + 1}
          isOnChain={workflowCompleted}
          info={<StepInfo data={info.extractionPoint.body} />}
        >
          {t("extraction_point.create.details")}
        </StepButton>
      ),
      panel: <ExtractionPointDetail />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>{t("extraction_point.update.step_2.info_intro")}</p>
            <p>{t("extraction_point.update.step_2.info_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep + 1}
          isOnChain={workflowCompleted}
          info={<StepInfo data={info.location.body} />}
        >
          {t("extraction_point.create.step_3.location")}
        </StepButton>
      ),
      panel: <ExtractionPointLocation />,
      infoPanel: (
        <InfoPanel
          actions={[
            {
              label: t("extraction_point.create.step_3.action_1"),
              action: () => {
                setLocationCoordinates({ lat: "", lng: "" });
                handleChangeDetails("address", "", "location");
                toastSuccess(
                  t("extraction_point.create.step_3.action_1_toast"),
                );
              },
            },
          ]}
        >
          <div className="space-y-2">
            <p>{t("extraction_point.create.step_3.info_intro")}</p>
            <LocationInformation />
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep + 1}
          isOnChain={workflowCompleted}
          info={<StepInfo data={info.meter.body} />}
        >
          {t("extraction_point.create.link_to_meter")}
        </StepButton>
      ),
      panel: <ExtractionPointLinkToMeter />,
      infoPanel: (
        <InfoPanel
          warnings={[
            details.meter.extractionPointName !== updatingPoint.name
              ? t("extraction_point.create.step_4.info_already_linked", {
                  serialNo: details.meter.serialNo,
                  extractionPointName: details.meter.extractionPointName,
                })
              : null,
          ].filter(Boolean)}
          actions={[
            {
              label: t("meter.add_new_meter"),
              action: () => {
                window.open(
                  `/polestar/subscribers/${details.subscriber.id}/level0_resources/${details.level0wrs.id}/meters/create?level1ResourceId=${details.level1wrs.id}&extractionPointId=${details.id}`,
                  "_blank",
                );
              },
            },
          ]}
        >
          <div className="space-y-4">
            <p>{t("extraction_point.create.step_4.info_intro")}</p>
            <p>{t("extraction_point.create.step_4.info_body_1")}</p>
            <p>{t("extraction_point.create.step_4.info_body_2")}</p>
            <p>{t("extraction_point.create.step_4.info_body_3")}</p>
            <p>{t("extraction_point.create.step_4.info_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep + 1}
          isOnChain={workflowCompleted}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("evidence.upload_menu_title")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          referenceTable="extraction_points"
          referenceTableId={details.id}
          onNext={activatedEvidenceIds => {
            handleChangeDetails(
              "activatedEvidenceIds",
              activatedEvidenceIds.map((v: any) => v),
            );
            stepHelpers.goToNextStep();
          }}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={() => navigateForCancel()}
          customDropboxTitle={
            t("extraction_point.create.step_5.title") as string
          }
          newUploadDescription={
            t("extraction_point.create.step_5.description", {
              extractionPointName: details.name,
            }) as string
          }
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>{t("extraction_point.create.step_5.info_intro")}</p>
            <p>{t("extraction_point.create.step_5.info_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={5}
          currentStep={currentStep + 1}
          isOnChain={workflowCompleted}
          forceSuccess={workflowCompleted}
        >
          {t("extraction_point.create.confirm_extraction_point")}
        </StepButton>
      ),
      panel: workflowCompleted ? (
        <ExtractionPointWorkflowCompleted />
      ) : (
        <ExtractionPointLedger />
      ),
      infoPanel: workflowCompleted ? (
        <InfoPanel
          successes={[
            t("extraction_point.create.workflow_complete.success_1", {
              extractionPointName: details.name,
              subscriberName: details.subscriber?.name,
            }),
          ]}
          actions={[
            {
              label: (
                <Trans
                  i18nKey="extraction_point.view_name"
                  values={{
                    extractionPointName: details.name,
                  }}
                />
              ),
              action: () =>
                navigate(
                  `/polestar/subscribers/${details.subscriber?.id}?level0ResourceId=${details.level0wrs?.id}&extractionPointId=${details.id}`,
                ),
            },
          ]}
        >
          <div className="space-y-4">
            <p>
              {true ? (
                <Trans
                  i18nKey="extraction_point.update.workflow_completed.info_from"
                  context="customer_dashboard"
                  values={{
                    extractionPointName: details.name,
                  }}
                />
              ) : (
                <Trans
                  i18nKey="extraction_point.update.workflow_completed.info_from"
                  context="scheme_dashboard"
                  values={{
                    extractionPointName: details.name,
                  }}
                />
              )}
            </p>
          </div>
        </InfoPanel>
      ) : (
        <InfoPanel
          successes={
            networkErrors.length > 0
              ? []
              : [t("extraction_point.create.step_6.success_1")]
          }
          errors={networkErrors}
        >
          <div className="space-y-4">
            <p>{t("information.edit_info_intro")}</p>
            <p className="pl-2">
              <Trans i18nKey="extraction_point.create.step_6.info_details" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="extraction_point.create.step_6.info_location" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="extraction_point.create.step_6.info_meter" />
            </p>
            <p className="pl-2">
              <Trans i18nKey="information.edit_info_evidence" />
            </p>
            <p>{t("information.edit_conclusion")}</p>
          </div>
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep + 1} steps={steps} />;
};

export default UpdateExtractionPointWorkflow;
