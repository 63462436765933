import { useQuery } from "@tanstack/react-query";
import {
  getAllAccountingPeriods,
  GetAllAccountingPeriodsParams,
} from "@services/accountingPeriod";

export const useAllAccountingPeriods = ({
  options,
  params,
}: {
  options?: Record<string, any>;
  params?: Partial<GetAllAccountingPeriodsParams>;
} = {}) => {
  return useQuery<any>({
    queryKey: ["accountingPeriods", { params }],
    queryFn: () => getAllAccountingPeriods(params),
    ...options,
  });
};
