import { useTranslation } from "react-i18next";

import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import { toastError, toastSuccess } from "@utils/toast";
import { useUpdateAccountingPeriodCheck } from "@hooks/mutation/useUpdateAccountingPeriodCheck";

type AccountingPeriodChecklistFooterProps = {
  isLastStep?: boolean;
  stepData?: any;
};

const AccountingPeriodChecklistFooter: React.FunctionComponent<
  AccountingPeriodChecklistFooterProps
> = ({ isLastStep, stepData = {} }) => {
  const { t } = useTranslation();
  const handleGoBackOrClose = HandleGoBackOrClose();
  const { currentAccountingPeriodCheck, saveData, isResetting } =
    useAccountingPeriodChecksContext();

  const { mutateAsync: saveAccountingPeriodCheckForLater } =
    useUpdateAccountingPeriodCheck({
      onSuccess: () => {
        toastSuccess(t("accounting_period.checklist.save_success"));
      },
      onError: (error: any) => {
        toastError(
          t("accounting_period.checklist.save_error", {
            error: error?.response?.data?.message || error?.message,
          }),
        );
      },
    });

  const handleCancelClick = () => {
    if (!isResetting) {
      handleGoBackOrClose();
      return;
    }

    const confirm = window.confirm(
      t("accounting_period.checklist.cancel_confirmation") as string,
    );

    if (confirm) {
      handleGoBackOrClose();
    }
  };

  const handleBackClick = () => {
    saveData({
      currentStep:
        currentAccountingPeriodCheck.currentStep <= 0
          ? 0
          : currentAccountingPeriodCheck.currentStep - 1,
    });
  };

  const handleNextClick = () => {
    const updatedData: any = {};
    for (const [key, value] of Object.entries(stepData)) {
      if (!currentAccountingPeriodCheck[key]) {
        updatedData[key] = value;
      }
    }

    saveData({
      ...updatedData,
      currentStep: currentAccountingPeriodCheck.currentStep + 1,
    });
  };

  const handleSaveClick = () => {
    saveAccountingPeriodCheckForLater({
      ...currentAccountingPeriodCheck,
      ...stepData,
    });
  };

  return (
    <footer className="flex gap-4 p-6 border-t border-gray-200">
      <button className="btn-outline-primary" onClick={handleCancelClick}>
        {t("common.cancel")}
      </button>
      {currentAccountingPeriodCheck?.currentStep > 0 && (
        <button className="btn-primary" onClick={handleBackClick}>
          {t("common.prev_step")}
        </button>
      )}
      {!isLastStep && (
        <button className="btn-primary" onClick={handleNextClick}>
          {t("common.next_step")}
        </button>
      )}
      {isResetting && (
        <button className="btn-primary" onClick={handleSaveClick}>
          {t("common.save_return")}
        </button>
      )}
    </footer>
  );
};

export default AccountingPeriodChecklistFooter;
