import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  ArrowTopRightOnSquareIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

import Modal from "@components/layout/Modal";
import Tag from "@components/shared/Tag";
import LedgerWorksLogo from "@components/icons/LedgerWorksIcon";
import HederaLogo from "@components/icons/HederaLogo";
import WaterLedgerLogo from "@components/icons/WaterLedgerLogo";
import { formatDateTime } from "@utils/formatDateTime";
import { getEventTransactionById } from "@services/zenith/eventTransaction";
import ENV from "@config/env";

type AuditTrailDetailModalProps = {
  open: boolean;
  id: string;
  onClose: () => void;
};

type Detail = {
  label: string;
  value: string;
};

const AuditTrailDetailModal: React.FunctionComponent<
  AuditTrailDetailModalProps
> = ({ open, id, onClose }) => {
  const { t } = useTranslation();
  const { data = {}, isLoading } = useQuery({
    queryKey: ["eventTransactions", "detail", id],
    queryFn: () => getEventTransactionById(id),
    enabled: Boolean(id),
  });

  const status = data?.hcsId ? "success" : "default";
  const details = [
    {
      label: t("audit_trail.event_group"),
      value: data.eventType?.eventGroup?.name,
    },
    {
      label: t("audit_trail.event_type"),
      value: data.eventType?.name,
    },
    {
      label: t("audit_trail.description"),
      value: data.description,
    },
    {
      label: t("audit_trail.calculation"),
      value: data.calculation,
    },
    // {
    //   label: t("audit_trail.ip_address"),
    //   value: data.ip,
    // },
    {
      label: t("common.timestamp"),
      value: formatDateTime(new Date(data.createdAt)),
    },
    {
      label: t("common.status"),
      value: (
        <Tag status={status}>
          {status === "success" ? t("common.success") : t("common.off_chain")}
        </Tag>
      ),
    },
  ];

  const onChainDetails = [
    {
      label: t("audit_trail.hcs_id"),
      value: data.hcsId,
    },
    {
      label: t("audit_trail.struck_at"),
      value: formatDateTime(new Date(data.struckAt)),
    },
    {
      label: t("audit_trail.hash"),
      value: data.hcsHash,
    },
  ];

  const hasHCS = data.hcsId;

  const transactionLink = (hasHCS: string) => {
    if (!hasHCS.includes("@")) {
      return hasHCS;
    }
    const hcsId = hasHCS.split("@")[0];
    const timestamp = hasHCS.split("@")[1];
    const newTimestamp = timestamp.replace(".", "-");
    return `${hcsId}-${newTimestamp}`;
  };

  return (
    <Modal open={open} isLoading={isLoading}>
      <div className="flex flex-col gap-6 divide-y [&>*]:pt-6 [&>*]:px-4 [&>footer]:py-4 text-gray-600">
        <header className="flex gap-2">
          <div className="w-8 shrink-0">
            <WaterLedgerLogo />
          </div>
          <h2 className="text-primary-2 text-xl">
            {t("audit_trail.modal_title")}
          </h2>
        </header>

        <div className="flex gap-2">
          <LockClosedIcon className="w5 h-5 text-green-500 shrink-0" />
          <p>{t("audit_trail.modal_subtitle")}</p>
        </div>

        <div className="flex items-start gap-4 relative text-sm">
          <div className="shrink-0">
            <HederaLogo />
          </div>
          <div className="flex-1 space-y-6">
            <Details
              title={
                <>
                  <span>{t("audit_trail.details")}</span>

                  {hasHCS ? (
                    <a
                      className="inline-flex font-normal text-sm text-primary-2 gap-1 hover:underline"
                      href={`${
                        ENV.LEDGER_WORKS_URL
                      }/transactions/${transactionLink(hasHCS)}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="h-5 w-5">
                        <LedgerWorksLogo />
                      </span>
                      {t("audit_trail.see_details")}
                      <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                    </a>
                  ) : null}
                </>
              }
              data={details}
            />

            {hasHCS ? (
              <Details
                title={t("audit_trail.on_chain_data")}
                data={onChainDetails}
              />
            ) : null}
          </div>
        </div>

        <footer className="flex justify-end">
          <button
            type="button"
            className="btn-default text-sm rounded"
            onClick={onClose}
          >
            {t("common.close")}
          </button>
        </footer>
      </div>
    </Modal>
  );
};

const Details: React.FunctionComponent<{
  title: React.ReactNode;
  data: Detail[];
}> = ({ title, data }) => {
  return (
    <div>
      <h4 className="flex justify-between items-baseline text-base font-bold mb-1">
        {title}
      </h4>
      <ul className="space-y-2">
        {data.map(i => {
          return i.value ? (
            <li key={i.label}>
              <dt className="font-bold leading-6">{i.label}</dt>
              <dd>{i.value}</dd>
            </li>
          ) : null;
        })}
      </ul>
    </div>
  );
};

export default AuditTrailDetailModal;
