import { useQuery } from "@tanstack/react-query";

import api from "@services/api";

const getAllAccountingPeriodChecks = async (
  params: {
    accountingPeriodId?: string;
  } = {},
) => {
  const { data } = await api.get("/admin/accountingPeriodChecks", { params });

  return data.accountingPeriodChecks;
};

export const useAllAccountingPeriodChecks = (
  options: {
    params?: { accountingPeriodId?: string };
    [key: string]: any;
  } = {},
) => {
  const { params, ...restOptions } = options;

  return useQuery<any>({
    queryKey: ["accountingPeriodChecks", { params }],
    queryFn: () => getAllAccountingPeriodChecks(params),
    ...restOptions,
  });
};
