import { useEffect, useState } from "react";
import Select from "react-select";
import classNames from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

import NotificationMessageViewModal from "../form/notification/NotificationMessageViewModal";
import Label from "@components/form/Label";
import Table from "@components/layout/Table";
import { useAllNotification } from "@hooks/query/useAllNotifications";
import { formatDateTime } from "@utils/formatDateTime";
import { Link } from "react-router-dom";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import { endOfDay, isValid, startOfDay, subYears } from "date-fns";
import TextInput from "@components/form/TextInput";
import { formatDateInput } from "@utils/formatDate";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";

type NotificationTableProps = {
  level1ResourceId?: any;
};

const NotificationTable: React.FunctionComponent<NotificationTableProps> = ({
  level1ResourceId,
}) => {
  const [filter, setFilter] = useState<{
    type?: string;
    startDate?: Date;
    endDate?: Date;
    recipientName?: string;
  }>({
    startDate: subYears(new Date(), 1),
    endDate: new Date(),
  });
  const { t } = useTranslation();
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [selectedNotification, setSelectedNotification] = useState<any>();
  const { isLoading, refetch: getNotifications } = useAllNotification({
    params: {
      level1ResourceId,
      fromDate: isValid(new Date(filter.startDate!))
        ? startOfDay(filter.startDate!)
        : undefined,
      toDate: isValid(new Date(filter.endDate!))
        ? endOfDay(filter.endDate!)
        : undefined,
      limit: -1,
    },
    onSuccess: ({ data }: any) => {
      setNotificationTypes(
        Array.from(new Set(data.map((e: any) => e.type?.name))),
      );
      setNotifications(data);
    },
  });

  const getNotificationTypeOptions = () => {
    return notificationTypes?.map(e => ({
      label: e,
      value: e,
    }));
  };

  const getRecipientNameOptions = () => {
    const options = notifications
      .filter(e => e.notifiedWallet?.subscribers?.[0]?.name)
      .map((e: any) => ({
        label: e.notifiedWallet?.subscribers?.[0]?.name,
        value: e.notifiedWallet?.subscribers?.[0]?.name,
      }));

    return options
      .filter(
        (option, index, self) =>
          option.value &&
          self.findIndex(t => t.value === option.value) === index,
      )
      .sort((a, b) => a.value.localeCompare(b.value));
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const tableFields = [
    {
      title: t("notification.recipient"),
      name: "recipientName",
    },
    {
      title: t("notification.type"),
      name: "type",
    },
    {
      title: t("notification.subject"),
      name: "subject",
    },
    {
      title: t("notification.created_at"),
      name: "sentAt",
    },
    {
      title: t("notification.level"),
      name: "level",
    },
    {
      title: t("common.actions"),
      name: "actions",
    },
  ];

  const tableData = notifications
    .filter(
      (row: { type: { name: string } }) =>
        !filter?.type || row.type?.name === filter?.type,
    )
    .filter(
      (row: any) =>
        !filter?.recipientName ||
        row.notifiedWallet?.subscribers[0]?.name === filter?.recipientName,
    )
    ?.map((notification: any) => ({
      ...notification,
      recipientName: notification.notifiedWallet?.subscribers?.[0]?.name,
      type: notification.type?.name,
      sentAt: formatDateTime(new Date(notification.createdAt)),
      subject: notification.relatedMessage?.subject,
      level:
        notification.level > 0 ? (
          <ExclamationCircleIcon
            className={classNames("w-6 h-6", {
              "text-yellow-500": notification.level === 1,
              "text-orange-500": notification.level === 2,
              "text-red-500": notification.level === 3,
              "text-black": notification.level === 4,
            })}
          />
        ) : null,
      actions: (
        <div className="flex gap-1 items-center w-full">
          <button
            type="button"
            className="btn-primary text-sm rounded"
            onClick={() => setSelectedNotification(notification)}
          >
            {t("common.view")}
          </button>
          <Link
            to={`/polestar/subscribers/${notification.notifiedWallet?.subscribers?.[0]?.id}`}
            target="_blank"
            rel="noreferrer"
            className="flex gap-3 items-center btn-default text-sm rounded"
          >
            <ExternalLinkIcon className="w-4 h-4" />
            {t("subscriber.view_subscriber")}
          </Link>
        </div>
      ),
    }));

  useEffect(() => {
    getNotifications();
  }, [filter.startDate, filter.endDate, getNotifications]);

  return (
    <>
      <header className="flex flex-col sm:flex-row gap-4 items-start">
        <div className="w-1/4">
          <Label htmlFor="type">{t("notification.type")}</Label>
          <Select
            options={getNotificationTypeOptions()}
            isClearable={true}
            value={getNotificationTypeOptions().find(
              e => e.value === filter?.type,
            )}
            onChange={(e: any) => {
              handleFilterChange("type", e?.value);
            }}
          />
        </div>
        <div className="w-1/4">
          <Label htmlFor="recipientName">{t("notification.recipient")}</Label>
          <Select
            options={getRecipientNameOptions()}
            isClearable={true}
            value={getRecipientNameOptions().find(
              option => option.value === filter?.recipientName,
            )}
            onChange={(
              selectedOption: { label: string; value: string } | null,
            ) => {
              console.log(tableData);
              handleFilterChange("recipientName", selectedOption?.value || "");
            }}
          />
        </div>
        <div className="w-1/4">
          <Label htmlFor="startDate">{t("reporting.filter_dates")}</Label>
          <div className="flex items-center gap-1">
            <TextInput
              type="date"
              id="startDate"
              max={filter.endDate ? formatDateInput(filter.endDate) : ""}
              placeholder={t("common.date_range.from") as string}
              value={filter.startDate ? formatDateInput(filter.startDate) : ""}
              onChange={e => {
                handleFilterChange(
                  "startDate",
                  isValid(new Date(e.target.value))
                    ? new Date(`${e.target.value}T00:00:00`)
                    : "",
                );
              }}
            />
            <ArrowRightCalendarIcon className="w-10 h-10" />
            <TextInput
              type="date"
              min={filter.startDate ? formatDateInput(filter.startDate) : ""}
              placeholder={t("common.date_range.to") as string}
              value={filter.endDate ? formatDateInput(filter.endDate) : ""}
              onChange={e => {
                handleFilterChange(
                  "endDate",
                  isValid(new Date(e.target.value))
                    ? new Date(`${e.target.value}T23:59:59`)
                    : "",
                );
              }}
            />
          </div>
        </div>
      </header>
      <Table
        pageSize={100}
        fields={tableFields}
        data={tableData}
        loading={isLoading}
        stickyHeader
      />
      <NotificationMessageViewModal
        message={selectedNotification?.relatedMessage}
        notification={selectedNotification}
        onClose={() => setSelectedNotification(undefined)}
      />
    </>
  );
};

export default NotificationTable;
