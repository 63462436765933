import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import api from "@services/api";

export const requestResetAccountingPeriod = async (
  accountingPeriodCheckId: string,
) => {
  const { data } = await api.post(
    "/admin/accountingPeriodChecks/requestReset",
    { accountingPeriodCheckId },
  );

  return [data.accountingPeriodCheck, data.workflowInstance];
};

export const useRequestResetAccountingPeriod = (
  options: Record<string, any> = {},
) => {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();

  return useMutation(requestResetAccountingPeriod, {
    ...restOptions,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["accountingPeriodChecks"],
      });

      if (isFunction(onSuccess)) {
        onSuccess();
      }
    },
  });
};
