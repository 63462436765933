import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Legend from "@components/form/Legend";
import { useExtractionPointContext } from "@context/ExtractionPointContext";
import LocationInputs, {
  MapInputOptions,
} from "@components/shared/LocationInputs";
import { ExtractionPointType } from "@services/extractionPoints";

const ExtractionPointLocation = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    details,
    locationCoordinates,
    setLocationCoordinates,
    saveLocationDetails,
    navigateForCancel,
  } = useExtractionPointContext();

  return (
    <form className="flex flex-col gap-6 p-6 grow justify-between">
      <Heading light>{t("extraction_point.create.step_3.location")}</Heading>
      <fieldset className="space-y-4">
        <Legend>{t("level1wrs.create.enter_coordinates")}</Legend>
        <LocationInputs
          locationCoordinates={locationCoordinates}
          setLocationCoordinates={setLocationCoordinates}
          predefinedInputOption={MapInputOptions.Map}
        />
      </fieldset>
      <div className="grow" />

      <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={() => {
            saveLocationDetails();
            stepHelpers.goToNextStep();
            if (details.type === ExtractionPointType.Quasi) {
              stepHelpers.goToNextStep();
            }
          }}
        >
          {t("common.next_step")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => navigateForCancel()}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default ExtractionPointLocation;
