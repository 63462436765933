import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isFunction } from "lodash";

import { createAccountingPeriod } from "@services/accountingPeriod";

export const useCreateAccountingPeriod = ({
  onSuccess,
  ...options
}: Record<string, any> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(createAccountingPeriod, {
    ...options,
    onSuccess: (res: any) => {
      queryClient.invalidateQueries({
        queryKey: ["accountingPeriods"],
      });

      if (isFunction(onSuccess)) {
        onSuccess(res);
      }
    },
  });
};
