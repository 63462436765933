import Card from "@components/layout/Card";
import Heading1 from "@components/layout/Heading";
import { useGeneralReportContext } from "@context/GeneralReportContext";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import GeneralReportFooter from "./GeneralReportFooter";

const GeneralReportStep2: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { saveData, goToNextStep, reportData, reportTables } =
    useGeneralReportContext();
  const [selectedTables, setSelectedTables] = useState<any[]>(
    reportData.selectedTables || [],
  );
  const [selectedFields, setSelectedFields] = useState<any[]>(
    reportData.selectedFields || [],
  );

  const toggleTable = (selected: boolean, selectedTable: any) => {
    let updatedSelectedTables: any[] = [];
    let updatedSelectedFields: any[] = [];

    if (selected) {
      for (const table of reportTables) {
        if (
          selectedTables.findIndex(t => t.name === table.name) !== -1 ||
          table.name === selectedTable.name
        ) {
          updatedSelectedTables.push(table);
        }
        for (const field of table.fields) {
          if (selectedFields.findIndex(f => f.name === field.name) !== -1) {
            updatedSelectedFields.push(field);
          }
        }
      }
    } else {
      for (const table of reportTables) {
        if (
          selectedTables.findIndex(t => t.name === table.name) !== -1 &&
          table.name !== selectedTable.name
        ) {
          updatedSelectedTables.push(table);
        }
        for (const field of table.fields) {
          if (
            selectedFields.findIndex(f => f.name === field.name) !== -1 &&
            table.name !== selectedTable.name
          ) {
            updatedSelectedFields.push(field);
          } else if (
            reportData.filters?.findIndex(f => f.field === field.name) !== -1
          ) {
            const updatedFilters = reportData.filters?.filter(
              f => f.field !== field.name,
            );
            saveData({ filters: updatedFilters });
          }
        }
      }
    }

    setSelectedTables(updatedSelectedTables);
    setSelectedFields(updatedSelectedFields);
  };

  const toggleField = (selected: boolean, selectedField: any) => {
    let updatedSelectedFields: any[] = [];

    if (selected) {
      for (const table of reportTables) {
        for (const field of table.fields) {
          if (
            selectedFields.findIndex(f => f.name === field.name) !== -1 ||
            field.name === selectedField.name
          ) {
            updatedSelectedFields.push(field);
          }
        }
      }
    } else {
      for (const table of reportTables) {
        for (const field of table.fields) {
          if (
            selectedFields.findIndex(f => f.name === field.name) !== -1 &&
            field.name !== selectedField.name
          ) {
            updatedSelectedFields.push(field);
          } else if (
            reportData.filters?.findIndex(f => f.field === field.name) !== -1
          ) {
            const updatedFilters = reportData.filters?.filter(
              f => f.field !== field.name,
            );
            saveData({ filters: updatedFilters });
          }
        }
      }
    }

    setSelectedFields(updatedSelectedFields);
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    saveData({ selectedTables, selectedFields });
    goToNextStep();
  };

  const toggleAllTables = (selectAll: boolean) => {
    if (selectAll) {
      setSelectedTables(reportTables);
    } else {
      setSelectedTables([]);
    }
  };

  const toggleAllFields = (selectAll: boolean) => {
    if (selectAll) {
      const allFields = reportTables.flatMap(table => table.fields);
      setSelectedFields(allFields);
    } else {
      setSelectedFields([]);
    }
  };

  return (
    <>
      <form onSubmit={handleSave} className="grow flex flex-col">
        <div className="p-4 grow flex flex-col">
          <Heading1 light>{t("reporting.general_step2_title")}</Heading1>
          <div className="text-sm">{t("reporting.general_step2_subtitle")}</div>
          <fieldset className="mt-4 space-y-4 flex flex-col">
            <div className="w-2/3 flex gap-2">
              <div className="w-1/2">
                <strong>{t("reporting.select_subjects")}</strong>
                <Card className="flex flex-col gap-2">
                  <div>
                    <input
                      type="checkbox"
                      id="selectAllTables"
                      className="mr-2"
                      onChange={e => toggleAllTables(e.target.checked)}
                    />
                    <label htmlFor="selectAllTables">
                      {t("reporting.select_all")}
                    </label>
                  </div>
                  {reportTables.map(table => (
                    <div key={table.name}>
                      <input
                        type="checkbox"
                        id={table.name}
                        className="mr-2"
                        value={table.name}
                        checked={
                          selectedTables.findIndex(
                            t => t.name === table.name,
                          ) !== -1
                        }
                        onChange={e => toggleTable(e.target.checked, table)}
                      />
                      <label htmlFor={table.name}>{table.label}</label>
                    </div>
                  ))}
                </Card>
              </div>
              <div className="w-1/2">
                <strong>{t("reporting.select_attributes")}</strong>
                <Card className="flex flex-col gap-2">
                  {selectedTables.length > 0 && (
                    <div>
                      <input
                        type="checkbox"
                        id="selectAllFields"
                        className="mr-2"
                        onChange={e => toggleAllFields(e.target.checked)}
                      />
                      <label htmlFor="selectAllFields">
                        {t("reporting.select_all")}
                      </label>
                    </div>
                  )}
                  {reportTables
                    .filter(
                      table =>
                        selectedTables.findIndex(t => t.name === table.name) !==
                        -1,
                    )
                    .map(table =>
                      table.fields.map((field: any) => (
                        <div key={field.name}>
                          <input
                            type="checkbox"
                            id={field.name}
                            className="mr-2"
                            value={field.name}
                            checked={
                              selectedFields.findIndex(
                                f => f.name === field.name,
                              ) !== -1
                            }
                            onChange={e => toggleField(e.target.checked, field)}
                          />
                          <label htmlFor={field.name}>{field.label}</label>
                        </div>
                      )),
                    )}
                </Card>
              </div>
            </div>
          </fieldset>
        </div>
        <GeneralReportFooter disabled={selectedFields.length <= 0} />
      </form>
    </>
  );
};

export default GeneralReportStep2;
