import { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import AlertIcon from "@components/icons/AlertIcon";
import CheckCircleIcon from "@components/icons/CheckCircleIcon";
import Heading from "@components/layout/Heading";
import Modal from "@components/layout/Modal";
import ConfirmModal from "@components/shared/ConfirmModal";
import AccountingPeriodChecklistFooter from "./AccountingPeriodChecklistFooter";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";
import { useAccountingPeriodChecksContext } from "@context/AccountingPeriodChecksContext";
import { useResetAccountingPeriod } from "@hooks/mutation/useResetAccountingPeriod";
import { useRequestResetAccountingPeriod } from "@hooks/mutation/useRequestResetAccountingPeriod";
import { useAppContext } from "@context/AppContext";
import { formatDate } from "@utils/formatDate";
import { toastError, toastSuccess } from "@utils/toast";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";
import { useUpdateAccountingPeriodCheck } from "@hooks/mutation/useUpdateAccountingPeriodCheck";

const FinalizeChecklist: React.FunctionComponent = () => {
  const {
    currentAccountingPeriodCheck,
    setWorkflowCompleted,
    setWorkflowInstance,
    nextAccountingPeriod,
  } = useAccountingPeriodChecksContext();
  const { t } = useTranslation();
  const { checkPermissions, loggedInInfo } = useAppContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const hasNextAccountingPeriod = Boolean(nextAccountingPeriod);
  const isCurrentAccountingPeriodActive =
    currentAccountingPeriodCheck?.accountingPeriod?.isActive;

  const { mutateAsync: requestReset } = useRequestResetAccountingPeriod();
  const { mutateAsync: resetAP, isLoading } = useResetAccountingPeriod();
  const { mutateAsync: saveAccountingPeriodCheckForLater } =
    useUpdateAccountingPeriodCheck({
      onSuccess: () => {
        toastSuccess(t("accounting_period.checklist.save_success"));
      },
      onError: (error: any) => {
        toastError(
          t("accounting_period.checklist.save_error", {
            error: error?.response?.data?.message || error?.message,
          }),
        );
      },
    });

  const handleRequestClick = async () => {
    await requestReset(currentAccountingPeriodCheck.id);
  };

  const handleResetClick = async () => {
    setIsConfirmationModalOpen(true);
  };

  const handleOnConfirmReset = async () => {
    try {
      await saveAccountingPeriodCheckForLater(currentAccountingPeriodCheck);
      const [, workflowInstance] = await resetAP({
        accountingPeriodCheckId: currentAccountingPeriodCheck.id,
        nextAccountingPeriodId: nextAccountingPeriod?.id ?? "",
      });
      setWorkflowInstance(workflowInstance);
      setWorkflowCompleted(true);
      toastSuccess(t("accounting_period.checklist.save_reset_success"));
      setIsConfirmationModalOpen(false);
    } catch (error: any) {
      toastError(
        t("accounting_period.checklist.save_reset_error", {
          error: error?.response?.data?.message || error?.message,
        }),
      );
    }
  };

  return (
    <>
      <div className="p-4 grow">
        <header>
          <Heading light>
            {t("accounting_period.checklist.reset_accounting_period")}
          </Heading>
          {checkPermissions(["ResetAccountingPeriod"]) &&
          isCurrentAccountingPeriodActive ? (
            <ul className="pt-4">
              <li className="flex items-start gap-2">
                <InformationCircleIcon
                  className={classNames(
                    "w-5 h-5 shrink-0",
                    hasNextAccountingPeriod
                      ? "text-green-600"
                      : "text-yellow-600",
                  )}
                />
                <span className="text-sm">
                  {hasNextAccountingPeriod ? (
                    <>
                      {t("common.next_accounting_period")}:{" "}
                      {formatAccountingPeriod(nextAccountingPeriod as any)}
                    </>
                  ) : (
                    <>
                      {t(
                        "accounting_period.checklist.warn_next_accounting_period",
                      )}
                    </>
                  )}
                </span>
              </li>
            </ul>
          ) : null}
        </header>
        <div className="flex justify-between mt-4">
          <div className="w-2/3 flex">
            <div className="w-1/2 flex flex-col">
              <Heading light className="text-sm">
                {t("accounting_period.checklist.checks")}
              </Heading>
              <ul className="flex flex-col gap-3">
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("common.level1wrs")}
                  </h3>

                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.permanentTradesCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.permanentTradesCheckedAt,
                      ),
                    )}
                  </div>
                </li>

                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period.checklist.permanent_trades")}
                  </h3>

                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.permanentTradesCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.permanentTradesCheckedAt,
                      ),
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period.checklist.seasonal_assignments")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.seasonalAssignmentsCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.seasonalAssignmentsCheckedAt,
                      ),
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period.checklist.meter_adjustments")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.meterAdjustmentsCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.meterAdjustmentsCheckedAt,
                      ),
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period.checklist.meter_reads")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.meterReadsCheckedBy?.name ||
                      loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.meterReadsCheckedAt,
                      ),
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period.checklist.water_harvestings")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.waterHarvestingsCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.waterHarvestingsCheckedAt,
                      ),
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period.checklist.distribution_losses")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.distributionLossesCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.distributionLossesCheckedAt,
                      ),
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period.checklist.forward_draws")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.forwardDrawsCheckedBy?.name ||
                      loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.forwardDrawsCheckedAt,
                      ),
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period.checklist.billing_reports")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.billingReportsCheckedBy
                      ?.name || loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.billingReportsCheckedAt,
                      ),
                    )}
                  </div>
                </li>
              </ul>
            </div>
            <div className="w-1/2 flex flex-col">
              <Heading light className="text-sm">
                {t("accounting_period.checklist.confirms")}
              </Heading>
              <ul className="flex flex-col gap-3">
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period.checklist.zone_volumes")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.zoneVolumesCheckedBy?.name ||
                      loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.zoneVolumesCheckedAt,
                      ),
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period.checklist.water_charges")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.waterChargesCheckedBy?.name ||
                      loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.waterChargesCheckedAt,
                      ),
                    )}
                  </div>
                </li>
                <li className="flex flex-col">
                  <h3 className="flex font-bold text-sm items-end mb-1">
                    <CheckCircleIcon className="text-green-600 w-6 h-6" />
                    {t("accounting_period.checklist.pending_admin_approvals")}
                  </h3>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_by")}:{" "}
                    </span>
                    {currentAccountingPeriodCheck.waterChargesCheckedBy?.name ||
                      loggedInInfo?.userDetails?.name}
                  </div>
                  <div className="text-gray-600 text-xs">
                    <span className="font-semibold">
                      {t("accounting_period.checklist.checked_at")}:{" "}
                    </span>
                    {formatDate(
                      new Date(
                        currentAccountingPeriodCheck.waterChargesCheckedAt,
                      ),
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-1/3">
            {!isCurrentAccountingPeriodActive ? (
              <button type="button" className="btn-primary" disabled>
                {t("accounting_period.checklist.reset_accounting_period")}
              </button>
            ) : checkPermissions(["ResetAccountingPeriod"]) ? (
              <button
                type="button"
                className="btn-primary"
                onClick={handleResetClick}
                disabled={!hasNextAccountingPeriod}
              >
                {t("accounting_period.checklist.reset_accounting_period")}
              </button>
            ) : (
              <button
                type="button"
                className="btn-primary"
                onClick={handleRequestClick}
                disabled={currentAccountingPeriodCheck?.status !== 0}
              >
                {t("accounting_period.checklist.request_reset")}
              </button>
            )}
          </div>
        </div>
      </div>
      <AccountingPeriodChecklistFooter isLastStep={true} />
      <Modal open={isModalOpen}>
        <div className="py-8 px-4 flex items-center gap-4">
          <div className="mx-auto flex h-12 w-12">
            <AlertIcon className="w-12 h-12" />
          </div>
          <Heading className="text-xl font-medium leading-6 text-primary-2">
            {t("accounting_period.checklist.request_reset_modal_confirmation")}
          </Heading>
        </div>
        <div className="mt-5 border-t px-4 py-2 flex flex-row-reverse gap-4">
          <button
            type="button"
            className="btn-primary text-base font-medium sm:col-start-1 sm:text-sm"
            onClick={() => setIsModalOpen(false)}
          >
            {t("common.close")}
          </button>
        </div>
      </Modal>
      <ConfirmModal
        open={isConfirmationModalOpen}
        onConfirm={handleOnConfirmReset}
        isSubmitting={isLoading}
        onClose={() => setIsConfirmationModalOpen(false)}
      >
        <Heading className="text-xl font-medium leading-6 text-primary-2">
          {t("accounting_period.checklist.request_reset_confirmation_modal")}
        </Heading>
      </ConfirmModal>
    </>
  );
};

export default FinalizeChecklist;
