import { useState } from "react";
import { useTranslation } from "react-i18next";

import BillingReportsChecklist from "@components/form/accounting_period_checks/BillingReportsChecklist";
import DistributionLossesChecklist from "@components/form/accounting_period_checks/DistributionLossesChecklist";
import FinalizeChecklist from "@components/form/accounting_period_checks/FinalizeChecklist";
import ForwardDrawsChecklist from "@components/form/accounting_period_checks/ForwardDrawsChecklist";
import MeterAdjustmentsChecklist from "@components/form/accounting_period_checks/MeterAdjustmentsChecklist";
import MeterReadingsChecklist from "@components/form/accounting_period_checks/MeterReadingsChecklist";
import PermanentTradesChecklist from "@components/form/accounting_period_checks/PermanentTradesChecklist";
import SeasonalAssignmentsChecklist from "@components/form/accounting_period_checks/SeasonalAssignmentsChecklist";
import SelectLevel1WRSChecklist from "@components/form/accounting_period_checks/SelectLevel1WRSChecklist";
import WaterChargesChecklist from "@components/form/accounting_period_checks/WaterChargesChecklist";
import WaterHarvestingsChecklist from "@components/form/accounting_period_checks/WaterHarvestingsChecklist";
import ZoneVolumesChecklist from "@components/form/accounting_period_checks/ZoneVolumesChecklist";
import Layout from "@components/layout/Layout";
import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import StepButton from "@components/shared/StepButton";
import CreateAccountingPeriodModal from "@components/modal/CreateAccountingPeriodModal";
import { useAppContext } from "@context/AppContext";
import {
  useAccountingPeriodChecksContext,
  AccountingPeriodChecksProvider,
} from "@context/AccountingPeriodChecksContext";
import { StatusInfo } from "../administrative_approvals/ApprovalIndex";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";
import { formatDate } from "@utils/formatDate";
import { formatAccountingPeriod } from "@utils/formatAccountingPeriod";

const CreateAccountingPeriodCheck: React.FunctionComponent = () => {
  return (
    <AccountingPeriodChecksProvider>
      <MainComponent />
    </AccountingPeriodChecksProvider>
  );
};

const MainComponent: React.FunctionComponent = () => {
  const {
    currentAccountingPeriodCheck,
    workflowCompleted,
    workflowInstance,
    nextAccountingPeriod,
  } = useAccountingPeriodChecksContext();
  const [openCreateAccountingPeriodModal, setOpenCreateAccountingPeriodModal] =
    useState(false);
  const hasNextAccountingPeriod = Boolean(nextAccountingPeriod);
  const isCurrentAccountingPeriodActive =
    currentAccountingPeriodCheck?.accountingPeriod?.isActive;
  const { loggedInInfo } = useAppContext();
  const { t } = useTranslation();

  const translationData = {
    accountingPeriod: formatAccountingPeriod(
      currentAccountingPeriodCheck?.accountingPeriod,
    ),
    level1wrsName: currentAccountingPeriodCheck?.level1WRS?.name,
  };

  let i = 0;
  const steps = [
    {
      label: (
        <StepButton
          index={i++}
          forceSuccess={currentAccountingPeriodCheck?.level1wrsCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.permanentTradesCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(currentAccountingPeriodCheck?.level1wrsCheckedAt),
                )}
              </div>
            </>
          }
        >
          {t("accounting_period.checklist.step_labels.select_scheme")}
        </StepButton>
      ),
      panel: <SelectLevel1WRSChecklist />,
      infoPanel: (
        <InfoPanel
          warnings={[
            t("accounting_period.checklist.info_panel.step_1.warning_1"),
          ]}
        />
      ),
    },
    {
      label: (
        <StepButton
          index={i++}
          forceSuccess={currentAccountingPeriodCheck?.permanentTradesCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.permanentTradesCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(
                    currentAccountingPeriodCheck?.permanentTradesCheckedAt,
                  ),
                )}
              </div>
            </>
          }
        >
          {t("accounting_period.checklist.step_labels.permanent_trades")}
        </StepButton>
      ),
      panel: <PermanentTradesChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period.checklist.info_panel.step_2.info", {
                ...translationData,
              })}
            </p>
            <p>{t("accounting_period.checklist.info_panel.carried_over")}</p>
            <StatusInfo />
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={i++}
          forceSuccess={
            currentAccountingPeriodCheck?.seasonalAssignmentsCheckedAt
          }
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.seasonalAssignmentsCheckedBy
                  ?.name || loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(
                    currentAccountingPeriodCheck?.seasonalAssignmentsCheckedAt,
                  ),
                )}
              </div>
            </>
          }
        >
          {t("accounting_period.checklist.step_labels.seasonal_assignments")}
        </StepButton>
      ),
      panel: <SeasonalAssignmentsChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period.checklist.info_panel.step_3.info", {
                ...translationData,
              })}
            </p>
            <p>{t("accounting_period.checklist.info_panel.carried_over")}</p>
            <StatusInfo />
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={i++}
          forceSuccess={currentAccountingPeriodCheck?.meterAdjustmentsCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.meterAdjustmentsCheckedBy
                  ?.name || loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(
                    currentAccountingPeriodCheck?.meterAdjustmentsCheckedAt,
                  ),
                )}
              </div>
            </>
          }
        >
          {t("accounting_period.checklist.step_labels.meter_adjustment")}
        </StepButton>
      ),
      panel: <MeterAdjustmentsChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period.checklist.info_panel.step_4.info", {
                ...translationData,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={i++}
          forceSuccess={currentAccountingPeriodCheck?.meterReadsCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.meterReadsCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(currentAccountingPeriodCheck?.meterReadsCheckedAt),
                )}
              </div>
            </>
          }
        >
          {t("accounting_period.checklist.step_labels.meter_read")}
        </StepButton>
      ),
      panel: <MeterReadingsChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period.checklist.info_panel.step_5.info", {
                ...translationData,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={i++}
          forceSuccess={currentAccountingPeriodCheck?.waterHarvestingsCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.waterHarvestingsCheckedBy
                  ?.name || loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(
                    currentAccountingPeriodCheck?.waterHarvestingsCheckedAt,
                  ),
                )}
              </div>
            </>
          }
        >
          {t("accounting_period.checklist.step_labels.water_harvesting")}
        </StepButton>
      ),
      panel: <WaterHarvestingsChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period.checklist.info_panel.step_6.info", {
                ...translationData,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={i++}
          forceSuccess={
            currentAccountingPeriodCheck?.distributionLossesCheckedAt
          }
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.distributionLossesCheckedBy
                  ?.name || loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(
                    currentAccountingPeriodCheck?.distributionLossesCheckedAt,
                  ),
                )}
              </div>
            </>
          }
        >
          {t("accounting_period.checklist.step_labels.distribution_losses")}
        </StepButton>
      ),
      panel: <DistributionLossesChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period.checklist.info_panel.step_7.info", {
                ...translationData,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={i++}
          forceSuccess={currentAccountingPeriodCheck?.forwardDrawsCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.forwardDrawsCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(currentAccountingPeriodCheck?.forwardDrawsCheckedAt),
                )}
              </div>
            </>
          }
        >
          {t("accounting_period.checklist.step_labels.forward_draws")}
        </StepButton>
      ),
      panel: <ForwardDrawsChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period.checklist.info_panel.step_8.info", {
                ...translationData,
              })}
            </p>
          </div>
          <StatusInfo />
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={i++}
          forceSuccess={currentAccountingPeriodCheck?.billingReportsCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.billingReportsCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(
                    currentAccountingPeriodCheck?.billingReportsCheckedAt,
                  ),
                )}
              </div>
            </>
          }
        >
          {t("accounting_period.checklist.step_labels.billing_reports")}
        </StepButton>
      ),
      panel: <BillingReportsChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period.checklist.info_panel.step_9.info", {
                ...translationData,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={i++}
          forceSuccess={currentAccountingPeriodCheck?.zoneVolumesCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.zoneVolumesCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(currentAccountingPeriodCheck?.zoneVolumesCheckedAt),
                )}
              </div>
            </>
          }
        >
          {t("accounting_period.checklist.step_labels.level0wrs_volumes")}
        </StepButton>
      ),
      panel: <ZoneVolumesChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period.checklist.info_panel.step_10.info", {
                ...translationData,
              })}
            </p>
            <div className="pt-2 flex gap-4">
              <span>
                <InformationCircleIcon className="text-blue-600 w-5 h-5"></InformationCircleIcon>
              </span>
              {t("accounting_period.checklist.info_panel.step_10.info_1")}
            </div>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={i++}
          forceSuccess={currentAccountingPeriodCheck?.waterChargesCheckedAt}
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
          info={
            <>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_by")}:{" "}
                </span>
                {currentAccountingPeriodCheck?.waterChargesCheckedBy?.name ||
                  loggedInInfo?.userDetails?.name}
              </div>
              <div className="text-gray-600 text-xs">
                <span className="font-semibold">
                  {t("accounting_period.checklist.checked_at")}:{" "}
                </span>
                {formatDate(
                  new Date(currentAccountingPeriodCheck?.waterChargesCheckedAt),
                )}
              </div>
            </>
          }
        >
          {t("accounting_period.checklist.step_labels.water_charges")}
        </StepButton>
      ),
      panel: <WaterChargesChecklist />,
      infoPanel: (
        <InfoPanel>
          <div className="space-y-4">
            <p>
              {t("accounting_period.checklist.info_panel.step_11.info", {
                ...translationData,
              })}
            </p>
            <div className="pt-2 flex gap-4">
              <span>
                <InformationCircleIcon className="text-blue-600 w-5 h-5"></InformationCircleIcon>
              </span>
              {t("accounting_period.checklist.info_panel.step_11.info_1")}
            </div>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={i++}
          forceSuccess={
            !currentAccountingPeriodCheck?.accountingPeriod?.isActive
          }
          currentStep={currentAccountingPeriodCheck?.currentStep}
          isOnChain={!currentAccountingPeriodCheck?.accountingPeriod?.isActive}
        >
          {t("accounting_period.checklist.step_labels.reset")}
        </StepButton>
      ),
      panel: workflowCompleted ? (
        <WorkflowComplete references={[workflowInstance?.id]} />
      ) : (
        <FinalizeChecklist />
      ),
      infoPanel: workflowCompleted ? (
        <InfoPanel
          successes={[
            t(
              "accounting_period.checklist.info_panel.workflow_completed.success_1",
              {
                ...translationData,
              },
            ),
          ]}
        />
      ) : (
        <InfoPanel
          warnings={[
            ...(!hasNextAccountingPeriod && isCurrentAccountingPeriodActive
              ? [t("accounting_period.checklist.warn_next_accounting_period")]
              : []),
          ]}
          actions={[
            ...(!hasNextAccountingPeriod && isCurrentAccountingPeriodActive
              ? [
                  {
                    action: () => {
                      setOpenCreateAccountingPeriodModal(true);
                    },
                    label: t("accounting_period.create.modal.title"),
                  },
                ]
              : []),
          ]}
        >
          <div className="space-y-4">
            <p>
              {t("accounting_period.checklist.info_panel.step_13.info", {
                ...translationData,
              })}
            </p>
          </div>
        </InfoPanel>
      ),
    },
  ];

  return (
    <>
      <Layout
        breadcrumb={[
          {
            label: t("common.home"),
            href: "/polestar",
          },
          {
            label: t("accounting_period.checklist.title"),
          },
        ]}
        title={t("accounting_period.checklist.title")}
        permissions={["CreateAccountingPeriodChecks"]}
        accountingPeriodLevel1ResourceId={
          currentAccountingPeriodCheck?.level1ResourceId
        }
      >
        <WorkflowPanels
          selectedStep={currentAccountingPeriodCheck?.currentStep}
          steps={steps}
        />
      </Layout>
      {currentAccountingPeriodCheck?.level1ResourceId && (
        <CreateAccountingPeriodModal
          open={openCreateAccountingPeriodModal}
          level1Resource={{
            id: currentAccountingPeriodCheck?.level1ResourceId,
            name: currentAccountingPeriodCheck?.level1WRS?.name,
          }}
          onClose={() => {
            setOpenCreateAccountingPeriodModal(false);
          }}
        />
      )}
    </>
  );
};

export default CreateAccountingPeriodCheck;
